.form__error-message {
  width: calc(100% - (22px * 2));
  max-width: 358px;
  min-height: 24px;
  
  color: #f00;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  
  display: block;
  
  margin: 0 auto 5px auto;
}