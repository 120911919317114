.footer__copyright {
  width: calc(100% - (19px * 2));
  max-width: 345px;
  
  color: #545454;
  font-size: clamp(14px, 3.5vw, 18px);
  font-weight: 400;
  line-height: clamp(18px, 4.5vw, 22px);
  
  margin: 0 auto;
}

@media (min-width: 425px) {
  .footer__copyright {
    width: calc(100% - (40px * 2));
    max-width: 824px;
  }
}

@media (min-width: 1024px) {
  .footer__copyright {
    width: calc(100% - (100px * 2));
    max-width: 880px;
  }
}
