.form__submit_disabled {
  color: rgba(0, 0, 0, .2);
  
  cursor: default;
  
  background-color: #fff;
  
  border: 1px solid rgba(0, 0, 0, .2);
  
  transition: all .2s ease-out;
}

.form__submit_disabled:hover {
  opacity: 1;
}