.places__like-button {
  min-width: 22px;
  aspect-ratio: 22 / 19;
  
  cursor: pointer;
  
  background-color: transparent;
  background-image: url('../../../images/like_disabled.svg');
  background-repeat: no-repeat;
  
  border: none;
  
  transition: opacity .4s ease-out;
}

.places__like-button:hover {
  opacity: .5;
  
  transition: opacity .2s ease-in;
}
