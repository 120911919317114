.header {
  box-sizing: border-box;
  width: 100%;
  max-width: 345px;
  
  margin: 0 auto;
  border-bottom: 1px solid rgba(84, 84, 84, .7);
}

@media (min-width: 425px) {
  .header {
    width: calc(100% - (40px * 2));
    max-width: 824px;
  }
}

@media (min-width: 1024px) {
  .header {
    width: calc(100% - (100px * 2));
    max-width: 880px;
  }
}
