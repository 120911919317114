.popup__message {
  max-width: 244px;
  
  color: #000;
  font-size: clamp(20px, 5vw, 24px);
  font-weight: 900;
  line-height: clamp(24px, 6vw, 29px);
  text-align: center;
  
  display: block;
  
  margin: 0 auto;
}

@media (min-width: 768px) {
  .popup__message {
    max-width: 358px;
  }
}