.profile__container {
  max-width: clamp(189px, 45vw, 336px);
  
  display: grid;
  grid-template-columns: 100% 1fr;
  gap: 8px 4px;
  align-items: center;
  
  margin: 26px 0 0 0;
}

@media (min-width: 768px) {
  .profile__container {
    grid-template-columns: auto 1fr;
    gap: 10px 18px;
    
    margin: 0 auto 0 0;
  }
}
