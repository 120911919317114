.header__account-container {
  display: flex;
  flex-direction: column;
  column-gap: 18px;
  align-items: center;
}

@media (min-width: 768px) {
  .header__account-container {
    flex-direction: row;
    row-gap: 24px;
  }
}