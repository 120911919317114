.header__logo {
  width: 104px;
  height: 24px;
  
  /*margin-left: 30px;*/
}

/*@media (min-width: 375px) {*/
/*  .header__logo {*/
/*    margin-left: 0;*/
/*  }*/
/*}*/

@media (min-width: 768px) {
  .header__logo {
    width: 142px;
    height: 33px;
  }
}