.places {
  width: calc(100% - (19px * 2));
  max-width: 345px;
  min-height: 100vh;
  
  margin: 0 auto;
}

@media (min-width: 425px) {
  .places {
    width: calc(100% - (40px * 2));
    max-width: 824px;
  }
}

@media (min-width: 1024px) {
  .places {
    width: calc(100% - (100px * 2));
    max-width: 880px;
  }
}
