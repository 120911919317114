.places__place-name {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  overflow: hidden;
  
  margin: 0;
}
