.profile__add-place-button {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  
  cursor: pointer;
  
  background-color: #000;
  
  position: relative;
  
  margin: 30px 0 0 0;
  border: 2px solid #fff;
  
  transition: opacity .4s ease-out;
}

.profile__add-place-button::before {
  content: '';
  
  width: 16px;
  height: 2px;
  
  background-color: #fff;
  
  position: absolute;
  
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.profile__add-place-button::after {
  content: '';
  
  width: 2px;
  height: 16px;
  
  background-color: #fff;
  
  position: absolute;
  
  top: calc(50% - 8px);
  left: calc(50% - 1px);
}

.profile__add-place-button:hover {
  opacity: .6;
  
  transition: opacity .2s ease-in;
}

.profile__add-place-button:focus {
  outline: none;
}

@media (min-width: 425px) {
  .profile__add-place-button {
    max-width: 150px;
  }
  
  .profile__add-place-button::before {
    width: 22px;
    
    top: calc(50% - 1px);
    left: calc(50% - 11px);
  }
  
  .profile__add-place-button::after {
    height: 22px;
    
    top: calc(50% - 11px);
    left: calc(50% - 1px);
  }
}

@media (min-width: 768px) {
  .profile__add-place-button {
    margin-top: 0;
  }
}
