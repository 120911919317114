.auth__link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  
  margin: 20px 0 0;
  
  transition: color .4s ease-out;
}

.auth__link:hover {
  color: rgba(255, 255, 255, .6);
  
  transition: color .2s ease-in;
}

@media (min-width: 768px) {
  .auth__link {
    margin-top: 15px;
  }
}