.places__list {
  list-style: none;
  
  display: grid;
  grid-template-columns: 100%;
  row-gap: 20px;
  
  margin: 0;
  padding: 0;
}

@media (min-width: 540px) {
  .places__list {
    grid-template-columns:
      minmax(224px, 335px)
      minmax(224px, 335px);
    column-gap: 17px;
  }
}

@media (min-width: 768px) {
  .places__list {
    grid-template-columns:
      minmax(218px, 282px)
      minmax(218px, 282px)
      minmax(218px, 282px);
  }
}
