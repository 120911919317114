.form__submit_place_auth {
  width: 100%;
  
  color: #000;
  font-size: clamp(16px, 5vw, 18px);
  line-height: clamp(19px, 5.5vw, 22px);
  
  background-color: #fff;
  
  border: 1px solid #fff;
}

.form__submit_place_auth:hover {
  opacity: .85;
}