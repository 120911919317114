@keyframes grow {
  from {
    opacity: 0;
    scale: .5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.grow {
  animation: grow .2s ease-in forwards;
}
