.profile__name {
  font-size: clamp(27px, 5.5vw, 42px);
  font-weight: 500;
  line-height: clamp(33px, 6.5vw, 48px);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  overflow: hidden;
  
  margin: 0;
}

@media (min-width: 768px) {
  .profile__name {
    text-align: left;
  }
}
