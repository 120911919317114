.form__submit {
  max-width: 358px;
  
  font-weight: 400;
  
  cursor: pointer;
  
  display: block;
  
  padding: 13px 0 13px 0;
  border-radius: 2px;
  
  transition: all .4s ease-out;
}

.form__submit:hover {
  transition: all .2s ease-in;
}