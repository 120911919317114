.profile__avatar {
  width: 120px;
  height: 120px;
  
  object-fit: cover;
  
  visibility: hidden;
  
  position: absolute;
}
