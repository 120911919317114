.profile__about {
  font-size: clamp(14px, 2.5vw, 18px);
  font-weight: 400;
  line-height: clamp(17px, 3.5vw, 22px);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  overflow: hidden;
  
  margin: 0;
}

@media (min-width: 768px) {
  .profile__about {
    text-align: left;
  }
}
