.spinner__wheel_size_big {
  width: 40px;
  height: 40px;
}

@media (min-width: 520px) {
  .spinner__wheel_size_big {
    width: 50px;
    height: 50px;
  }
}