.form__input {
  max-width: 358px;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  
  display: block;
  
  border: none;
}

.form__input:focus {
  outline: none;
}