.places__trash-button {
  width: 18px;
  height: 19px;
  
  background-color: transparent;
  background-image: url('../../../images/delete-place-pict.svg');
  background-repeat: no-repeat;
  background-size: contain;
  
  display: block;
  
  position: absolute;
  
  top: 20px;
  right: 20px;
  border: none;
  
  z-index: 1;
  
  transition: opacity .4s ease-out;
}

.places__trash-button:hover {
  cursor: pointer;
  
  opacity: .6;
  
  transition: opacity .2s ease-in;
}
